<template xmlns="http://www.w3.org/1999/html">
	<div>
		<div style="width: 90%;margin: 20px auto 20px 10%; display: flex;justify-content: center;align-items: center">
			
			<!--		  <div>{{'选择场所所在地区：'}}</div>-->
			<!--		  <el-radio-group v-model="tableForm.contractId">-->
			<!--			  <el-radio-button :disabled="projectId !==0" v-for="city in areaList" :label="city.id" :key="city.id">{{city.customerName}}</el-radio-button>-->
			<!--		  </el-radio-group >-->
		</div>
		<div class="titleSty">
			{{ tableTitle }}
		</div>
		<div class="tableTop">
			<div class="tableTop">
				<div>申请人（盖章）：</div>
				<el-input v-model="name" :disabled="isDisabled" class="topInputLeft"></el-input>
				<div>（印章）</div>
			</div>
			<div class="tableTop">
				<div>申请日期：</div>
				<el-input v-model="tableForm.applicationTimer" :disabled="isDisabled" class="topInput"></el-input>
			</div>
		</div>
		<!--设置表格属性-->
		<div class="tableBox">
			<table collapse border="'0.1"
			       style="border-collapse:collapse;text-align: center;margin: 0 auto;" width="1100"
			       cellspacing="0.1"
			       cellpadding="1" bgcolor="" bordercolor="black"
			>
				<tr style="height: 60px">
					<td>场所名称 <br>（统一社会信用代码）</td>
					<td colspan="7">
						<el-input  type="textarea" :rows="2" v-model="tableForm.projectName"  :disabled="isDisabled"></el-input>
					</td>
					
					<td>法定代表人/
						主要负责人</td>
					<td colspan="3">
						<el-input type="" v-model="tableForm.contactName" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				
				<tr>
					<td>公民身份号码</td>
					<td colspan="7">
						<el-input v-model="tableForm.contactCard" :disabled="isDisabled"></el-input>
					</td>
					<td>联系电话</td>
					<td colspan="3">
						<el-input v-model="phone" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				<tr>
					<td>地址</td>
					<td colspan="7">
						<el-input v-model="tableForm.projectAddress" :disabled="isDisabled"></el-input>
					</td>
					<td>建筑结构</td>
					<td colspan="3">
						<el-input v-model="tableForm.structureType" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				<tr>
					<td>场所建筑面积</td>
					<td colspan="7">
						<el-input v-model="tableForm.projectArea" :disabled="isDisabled"></el-input>
					</td>
					<td>使用层数（地上）</td>
					<td colspan="1">
						<el-input v-model="tableForm.overgroundFloors" :disabled="isDisabled"></el-input>
					</td>
					<td>使用层数（地下）</td>
					<td colspan="1">
						<el-input v-model="tableForm.undergroundFloors" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				<tr>
					<td colspan="2" rowspan = '2'>场所性质</td>
					<td colspan="10">
						<el-checkbox-group style="text-align: left;" v-model="tableForm.naturePlace" :disabled="isDisabled">
							<el-checkbox label="436">影剧院、录像厅、礼堂等演出、放映场所</el-checkbox>
							<el-checkbox label="437">舞厅、卡拉ＯＫ厅等歌舞娱乐场所</el-checkbox>
							<el-checkbox label="439">游艺、游乐场所</el-checkbox>
							<el-checkbox label="441">桑拿浴室</el-checkbox>
							<el-checkbox label="438">具有娱乐功能的夜总会、音乐茶座和餐饮场所</el-checkbox>
							<el-checkbox label="440">保龄球馆、旱冰场</el-checkbox>
							<el-checkbox label="414">其他</el-checkbox>
						</el-checkbox-group>
					</td>
				</tr>
				<tr>
					<td colspan="10">
						<el-checkbox-group style="text-align: left;"  v-model="tableForm.naturePlaceOther" :disabled="isDisabled">
							<el-checkbox label="443">宾馆、饭店</el-checkbox>
							<el-checkbox label="444">商场</el-checkbox>
							<el-checkbox label="445">集贸市场</el-checkbox>
							<el-checkbox label="446">客运车站候车室</el-checkbox>
							<el-checkbox label="447">客运码头候船厅</el-checkbox>
							<el-checkbox label="448">民用机场航站楼</el-checkbox>
							<el-checkbox label="451">会堂</el-checkbox>
							<el-checkbox label="449">体育场馆</el-checkbox>
							<el-checkbox label="442">其他</el-checkbox>
						</el-checkbox-group>
					</td>
				</tr>
				
				<tr>
					<td colspan="2" rowspan = '14'>场所所在建筑情况</td>
					<td>名称</td>
					<td colspan="6">
						<el-input v-model="tableForm.buildName" :disabled="isDisabled"></el-input>
					</td>
					<td>建筑面积</td>
					<td colspan="2">
						<el-input v-model="tableForm.buildArea" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				<tr>
					<td colspan="4">建筑层数（地上）</td>
					<td colspan="2">
						<el-input v-model="tableForm.buildOvergroundFloors" :disabled="isDisabled"></el-input>
					</td>
					<td>建筑层数（地下）</td>
					<td colspan="1">
						<el-input v-model="tableForm.buildUndergroundFloors" :disabled="isDisabled"></el-input>
					</td>
					
					<td>建筑高度</td>
					<td colspan="1">
						<el-input v-model="tableForm.buildHeight" :disabled="isDisabled"></el-input>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;" >
								<el-checkbox v-model="tableForm.buildJsonData[0].isUse" :disabled="isDisabled">消防车道</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否畅通：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[0].value" >
									<el-radio :disabled="!tableForm.buildJsonData[0].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[0].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[1].isUse" :disabled="isDisabled">消防车登高操作场地</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[1].value">
									<el-radio :disabled="!tableForm.buildJsonData[1].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[1].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[2].isUse" :disabled="isDisabled">室外消火栓</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否完好有效：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[2].value">
									<el-radio :disabled="!tableForm.buildJsonData[2].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[2].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[3].isUse" :disabled="isDisabled">水泵接合器</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否完好有效：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[3].value">
									<el-radio :disabled="!tableForm.buildJsonData[3].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[3].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[4].isUse" :disabled="isDisabled">消防控制室</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[4].value">
									<el-radio :disabled="!tableForm.buildJsonData[4].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[4].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[5].isUse" :disabled="isDisabled">消防水泵房 </el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[5].value">
									<el-radio :disabled="!tableForm.buildJsonData[5].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[5].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[6].isUse" :disabled="isDisabled" >消防电梯</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[6].value">
									<el-radio :disabled="!tableForm.buildJsonData[6].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[6].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[7].isUse" :disabled="isDisabled">柴油发电机房</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[7].value">
									<el-radio :disabled="!tableForm.buildJsonData[7].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[7].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[8].isUse" :disabled="isDisabled">燃油或燃气锅炉房</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[8].value">
									<el-radio :disabled="!tableForm.buildJsonData[8].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[8].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<el-checkbox v-model="tableForm.buildJsonData[9].isUse" :disabled="isDisabled">变压器室</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[9].value">
									<el-radio :disabled="!tableForm.buildJsonData[9].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[9].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;" >
								<el-checkbox v-model="tableForm.buildJsonData[10].isUse" :disabled="isDisabled">配电室</el-checkbox>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<span>是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[10].value">
									<el-radio :disabled="!tableForm.buildJsonData[10].isUse" :label="true">是</el-radio>
									<el-radio :disabled="!tableForm.buildJsonData[10].isUse" :label="false">否</el-radio>
								</el-radio-group>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr>
					<td colspan="10" class="table-td-border" style="border-bottom: none;border-top: none">
						<el-row :gutter="10" type="flex" justify="left">
							<el-col :span="10" style="text-align: left;">
								<div class="location">
									<el-checkbox v-model="tableForm.buildJsonData[11].isUse" :disabled="isDisabled">其他专用房间：</el-checkbox>
									<el-input v-model="tableForm.buildJsonData[11].detail" :disabled="isDisabled"></el-input>
								</div>
							</el-col>
							<el-col :span="10" style="text-align: left;">
								<div class="location" style="margin-top: 20px">
									<span>是否符合消防安全要求：</span>
									<el-radio-group style="margin-left: 10px" v-model="tableForm.buildJsonData[11].value">
										<el-radio :disabled="!tableForm.buildJsonData[11].isUse" :label="true">是</el-radio>
										<el-radio :disabled="!tableForm.buildJsonData[11].isUse" :label="false">否</el-radio>
									</el-radio-group>
								</div>
							</el-col>
						</el-row>
					</td>
				</tr>
				<tr >
					<td colspan="2" rowspan="4">场所情况</td>
					<td colspan="4" style="border-bottom:none"></td>
					<td colspan="6" style="border-bottom:none">
						<div style="display: flex;align-items: center;">
							<span style="width: 285px;text-align: left">电器线路设计单位：</span>
							<el-input v-model="tableForm.placeJsonData[0].data[0].value" :disabled="isDisabled"></el-input>
						</div>
					</td>
				</tr>
				<tr >
					<td colspan="4" style="border-top: none;">
						用火 <br><br><br> 用电
					</td>
					<td colspan="6" rowspan="" style="border-top: none;">
						<template>
							<div class="location" >
								<span style="width: 40%;text-align: left">电气线路施工单位：</span>
								<el-input v-model="tableForm.placeJsonData[1].data[0].value" :disabled="isDisabled"></el-input>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">电器产品是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[1].data[1].isUse">
									<el-radio :label="true" :disabled="isDisabled">是</el-radio>
									<el-radio :label="false" :disabled="isDisabled">否</el-radio>
								</el-radio-group>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">场所是否使用燃气：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[1].data[2].isUse">
									<el-radio :label="true" :disabled="isDisabled">是</el-radio>
									<el-radio :label="false" :disabled="isDisabled">否</el-radio>
								</el-radio-group>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">燃气类型：</span>
								<el-input v-model="tableForm.placeJsonData[1].data[3].value" :disabled="isDisabled"></el-input>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">燃气施工（安装）单位：</span>
								<el-input v-model="tableForm.placeJsonData[1].data[4].value" :disabled="isDisabled"></el-input>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">燃气用具是否符合消防安全要求：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[1].data[5].isUse">
									<el-radio :label="true" :disabled="isDisabled">是</el-radio>
									<el-radio :label="false" :disabled="isDisabled">否</el-radio>
								</el-radio-group>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">场所是否使用燃油：</span>
								<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[1].data[6].isUse">
									<el-radio :label="true" :disabled="isDisabled">是</el-radio>
									<el-radio :label="false" :disabled="isDisabled">否</el-radio>
								</el-radio-group>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">燃油储存位置及储量：</span>
								<el-input v-model="tableForm.placeJsonData[1].data[7].value" :disabled="isDisabled"></el-input>
							</div>
							<div class="location">
								<span style="width: 40%;text-align: left">其他用火用电情况：</span>
								<el-input v-model="tableForm.placeJsonData[1].data[8].value" :disabled="isDisabled"></el-input>
							</div>
						</template>
					</td>
				</tr>
				<tr>
					<td colspan="4">
						安全疏散
					</td>
					<td colspan="6" rowspan="">
						<template>
							<div  class="" style="border-bottom: none;border-top: none">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;" >
										<div class="location">
											<span style='width: 260px'>安全出口数量：</span>
											<el-input v-model="tableForm.placeJsonData[2].data[0].value" :disabled="isDisabled"></el-input>
										</div>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<div class="location" style="align-items: center;margin-top: 20px">
											<span style='width: 50%'>是否畅通：</span>
											<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[0].isUse">
												<el-radio :label="true">是</el-radio>
												<el-radio :label="false">否</el-radio>
											</el-radio-group>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="location">
								<span style="width: 28%;text-align: left">疏散楼梯设置形式：</span>
								<el-input v-model="tableForm.placeJsonData[2].data[1].value" :disabled="isDisabled"></el-input>
							</div>
							<div  class="" style="border-bottom: none;border-top: none">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;" >
										<div class="location">
											<span style='width: 260px'>疏散楼梯数量：</span>
											<el-input v-model="tableForm.placeJsonData[2].data[2].value" :disabled="isDisabled"></el-input>
										</div>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<div class="location" style="align-items: center;margin-top: 20px">
											<span style='width: 50%'>是否畅通：</span>
											<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[2].isUse">
												<el-radio :label="true">是</el-radio>
												<el-radio :label="false">否</el-radio>
											</el-radio-group>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="location">
								<span style="width: 28%;text-align: left">避难层（间）设置位置：</span>
								<el-input v-model="tableForm.placeJsonData[2].data[3].value" :disabled="isDisabled"></el-input>
							</div>
							<div  class="" style="border-bottom: none;border-top: none">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;" >
										<div class="location">
											<span style='width: 260px'>避难层（间）数量：</span>
											<el-input v-model="tableForm.placeJsonData[2].data[4].value" :disabled="isDisabled"></el-input>
										</div>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<div class="location" style="align-items: center;margin-top: 20px">
											<span style='width: 55%'>是否符合消防安全要求：</span>
											<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[4].isUse">
												<el-radio :label="true">是</el-radio>
												<el-radio :label="false">否</el-radio>
											</el-radio-group>
										</div>
									</el-col>
								</el-row>
							</div>
							<div  class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[2].data[5].value" :disabled="isDisabled">消防应急广播</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[5].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[2].data[6].value" :disabled="isDisabled">消防应急照明</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[6].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[2].data[7].value" :disabled="isDisabled">疏散指示标志</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[2].data[7].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
						</template>
					</td>
				</tr>
				<tr>
					<td colspan="4">
						消防设施
					</td>
					<td colspan="6" rowspan="">
						<template>
							<div  class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[0].isShow" :disabled="isDisabled">室内消火栓</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[0].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[1].isShow" :disabled="isDisabled">自动喷水灭火系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[1].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[2].isShow" :disabled="isDisabled">火灾自动报警系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[2].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[3].isShow" :disabled="isDisabled">气体灭火系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[3].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[4].isShow" :disabled="isDisabled">泡沫灭火系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[4].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[5].isShow" :disabled="isDisabled">机械防烟系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[5].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<el-checkbox v-model="tableForm.placeJsonData[3].data[6].isShow" :disabled="isDisabled">机械排烟系统</el-checkbox>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<span>是否完好有效：</span>
										<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[6].isUse">
											<el-radio :label="true" :disabled="isDisabled">是</el-radio>
											<el-radio :label="false" :disabled="isDisabled">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<el-row :gutter="20" type="flex" justify="left">
									<el-col :span="14" style="text-align: left;">
										<div class="location">
											<el-checkbox v-model="tableForm.placeJsonData[3].data[7].isShow" :disabled="isDisabled">其他消防设施：</el-checkbox>
											<el-input v-model="tableForm.placeJsonData[3].data[7].value" :disabled="isDisabled"></el-input>
										</div>
									</el-col>
									<el-col :span="10" style="text-align: left;">
										<div class="location" style="margin-top: 20px">
											<span>是否完好有效：</span>
											<el-radio-group style="margin-left: 10px" v-model="tableForm.placeJsonData[3].data[7].isUse">
												<el-radio :label="true" :disabled="isDisabled">是</el-radio>
												<el-radio :label="false" :disabled="isDisabled">否</el-radio>
											</el-radio-group>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="table-td-border" style="border-bottom: none;border-top: none;margin-top: 10px">
								<div class="location" style="margin-top: 10px">
									<el-checkbox v-model="tableForm.placeJsonData[3].data[8].isShow" :disabled="isDisabled">灭火器种类、型号和数量</el-checkbox>
									<el-input style="margin-left: 15px" v-model="tableForm.placeJsonData[3].data[8].value" :disabled="isDisabled"></el-input>
								</div>
							</div>
						</template>
					</td>
				</tr>
				
				<tr>
					<td colspan="2" rowspan="2" style="width: 20%">
						室内装修
					</td>
					<td colspan="3">装修部位</td>
					<td >顶棚</td>
					<td >墙面</td>
					<td >地面</td>
					<td >隔断</td>
					<td >固定家具</td>
					<td >装饰织物</td>
					<td >其他</td>
				</tr>
				<tr>
					<td  colspan="3">装修材料燃烧性能等级</td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[0].value" :disabled="isDisabled"></el-input></td>
					<td><el-input v-model="tableForm.buildRenovationJsonData[1].value" :disabled="isDisabled"></el-input></td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[2].value" :disabled="isDisabled"></el-input></td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[3].value" :disabled="isDisabled"></el-input></td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[4].value" :disabled="isDisabled"></el-input></td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[5].value" :disabled="isDisabled"></el-input></td>
					<td ><el-input v-model="tableForm.buildRenovationJsonData[6].value" :disabled="isDisabled"></el-input></td>
				</tr>
				<tr>
					<td colspan="12">
						<div style="display: flex;align-items: center;">
							<span style="width: 22%;text-align: left">其他需要说明的情况：</span>
							<el-input v-model="tableForm.notes" :disabled="isDisabled"></el-input>
						</div>
					</td>
				</tr>
			
			</table>
			<!--	   <div style="width: 90%;margin: 20px auto">-->
			<!--		   <input type="file" @change="handleFileUpload" />-->
			<!--		   <span style="color: #2d8cf0">附件提示上传成功后在进行保存！</span>-->
			<!--		   <div v-for="child in fileList" class="fileListItem">-->
			<!--			   <div>-->
			<!--				   {{ child.name }}-->
			<!--			   </div>-->
			<!--			   <div>-->
			<!--				   <el-button type="text" size="mini" @click="deleteDrawing(child)">删除</el-button>-->
			<!--			   </div>-->
			<!--		   </div>-->
			<!--	   </div>-->
			<div v-if="!dataHideBtn" slot="footer" class="dialog-footer bottomBtn">
<!--				<el-button type="primary" @click="colosedDialog" >取消</el-button>-->
<!--				<el-button type="primary" v-if="!isDisabled" @click="showConfirmDialogSubmit" :loading="showConfirmDialogLoading">保存</el-button>-->
				<!--        <el-button type="primary" @click="uploadFileSubmit">上传识别文件</el-button>-->
			</div>
		</div>
	</div>
</template>

<script>
import util from '../../../util/date'
import {
	addProject,
	updateProject,
	getAccountInfo,
	getProjectApplicationByProjectId,
} from '../../api/api'
import ObsClient from "esdk-obs-browserjs/src/obs";
import api from "@/api";

function processResponse (resp) {

}

export default {
	data () {
		return {
			tableTitle: this.isOr===1?'消防安全检查申报（非告知承诺方式）':'消防安全检查基本信息登记（告知承诺方式）',
			showConfirmDialogLoading: false,
			tableForm: {
				structureType: '',//建筑结构
				naturePlace:[],//场所性质（上）
				naturePlaceOther:[],//场所性质
				applicationPeople : '',//申请人
				applicationTimer:'',//申请日期
				buildArea:0,//场所所在建筑情况：场所建筑面积
				buildHeight : 0 , //场所所在建筑情况：场所高度
				buildJsonData:[
					{
						id:0,
						title:'消防车道',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:1,
						title:'消防车登高操作场地',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:2,
						title:'室外消火栓 ',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:3,
						title:'水泵接合器',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:4,
						title:'消防控制室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:5,
						title:'消防水泵房',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:6,
						title:'消防电梯',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:7,
						title:'柴油发电机房',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:8,
						title:'燃油或燃气锅炉房 ',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:9,
						title:'变压器室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:10,
						title:'配电室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:11,
						title:'其他专用房间',
						isUse:undefined,
						detail:undefined,
						type:'',
						value:undefined,
					},
				],//场所所在建筑情况Json结构体 [{id:0,title:消防车道,type:radio,value:true}]
				buildName : undefined , //场所所在建筑情况：场所名称
				buildOvergroundFloors : 0 , //场所所在建筑情况：场所地上层数
				buildRenovationJsonData : [
					{
						id:0,
						title:'顶棚',
						type:'text',
						value:''
					},
					{
						id:1,
						title:'墙面',
						type:'text',
						value:''
					},
					{
						id:2,
						title:'地面',
						type:'text',
						value:''
					},
					{
						id:3,
						title:'隔断',
						type:'text',
						value:''
					},
					{
						id:4,
						title:'固定家具',
						type:'text',
						value:''
					},
					{
						id:5,
						title:'装饰织物',
						type:'text',
						value:''
					},
					{
						id:6,
						title:'其他',
						type:'text',
						value:''
					},
				] ,//室内装修Json结构体 [{title:顶棚,type:text,value:xxx}]
				buildUndergroundFloors : 0 ,//场所所在建筑情况：场所地下层数
				contactCard : undefined ,//身份证
				contactName : undefined ,//法定代表人/主要负责人
				contactPhone : undefined ,//联系电话
				contractId : 0 ,//合同id
				notes : undefined ,//其他需要说明的情况
				overgroundFloors : 0 ,//地上层数
				placeJsonData : [
					{
						name:'用火',
						data:[
							{
								title:'电气线路设计单位',
								isUse:undefined,
								type:'text',
								value:undefined,
							},
						]
					},
					{
						name:'用电',
						data:[
							{
								title:'电气线路施工单位',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'电器产品是否符合消防安全要求',
								isUse:undefined,
								type:'radio',
								value:'',
							},
							{
								title:'场所是否使用燃气',
								isUse:undefined,
								id:7,
								type:'radio',
								value:''
							},
							{
								title:'燃气类型',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'燃气施工（安装）单位',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'燃气用具是否符合消防安全要求',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'场所是否使用燃油',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'燃油储存位置及储量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'其他用火用电情况',
								isUse:undefined,
								type:'text',
								value:''
							}
						]
					},
					{
						name:'安全疏散',
						data:[
							{
								title:'安全出口数量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'疏散楼梯设置形式',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'疏散楼梯数量',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'避难层（间）设置位置',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'避难层（间）数量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'消防应急广播',
								type:'text',
								isUse:undefined,
								value:''
							},
							{
								title:'消防应急照明',
								isUse:undefined,
								id:4,
								type:'text',
								value:''
							},
							{
								title:'疏散指示标志',
								isUse:undefined,
								id:4,
								type:'text',
								value:''
							},
						]
					},//
					{
						name:'消防设施',
						data:[
							{
								title:'室内消火栓',
								isUse:undefined,
								id:13,
								type:'text',
								value:''
							},
							{
								title:'自动喷水灭火系统',
								isUse:undefined,
								id:14,
								type:'text',
								value:''
							},
							{
								title:'火灾自动报警系统',
								isUse:undefined,
								id:6,
								type:'text',
								value:''
							},
							{
								title:'气体灭火系统',
								isUse:undefined,
								id:15,
								type:'text',
								value:''
							},
							{
								title:'泡沫灭火系统',
								isUse:undefined,
								id:19,
								type:'text',
								value:''
							},
							{
								title:'机械防烟系统',
								isUse:undefined,
								type:'text',
								id:16,
								value:''
							},
							{
								title:'机械排烟系统',
								isUse:undefined,
								type:'text',
								id:17,
								value:''
							},
							{
								title:'其他消防设施',
								isShow:false,
								isUse:undefined,
								type:'text',
								id:19,
								value:''
							},
							{
								title:'灭火器种类、型号和数量',
								isShow: false,
								isUse:undefined,
								type:'text',
								id:18,
								value:''
							},
						]
					}
				],//场所情况Json结构体 [{name:用火用电,data:[{title:电气线路设计单位,type:text,value:xx}]
				projectAddress : '' ,//地址
				projectApplicationFileUrl:undefined,//消防安全检查申报表附件
				projectArea : 0 ,//场所建筑面积
				projectFireFacilitiesIds : '' ,//安全疏散及消防设施 (逗号分割) 1:室内消火栓系统 2:室外消火栓系统 3:火灾自动报警系统 4:自动喷水灭火系统
				// 5:气体灭火系统 6:泡沫灭火系统 7:其他灭火系统 8:疏散指示标志 9:消防应急照明 10:防烟排烟系统 11:消防电梯 12:灭火器 13:其他
				projectId : undefined ,//项目id
				projectName : '' ,//场所名称
				undergroundFloors : 0 ,//场所地下层数
				venueNatureIds  : '' ,//	场所性质（逗号分割）（字典命名空间：BizSevice.DrProject.VenueNature）
			},
			uploadFileDialog: false,
			uploadImagesUrl: '',
			Id:null,
			name:'',
			phone:0,
			areaLists:[],
			areaValue:[],
			Bucket: process.env.VUE_APP_BUCKET_NAME,  // 存储桶名称
			obsClient: null,  // 初始化OBS客户端
			uploading: false, // 上传状态
			Area: process.env.VUE_APP_SERVER_ACCESS_AREA_A,    // 区域名称
			keyValue:'',
			drawingUrl:'',
			fileList: [],
			businessLicense:[],
			vLoading:false,
			provinceList:undefined,
		}
	},
	props: ['dataHideBtn','responseSheet', 'projectId','tableForms', 'isDisabled', 'needData','userInfo','areaList','querys','isOr'],
	methods: {
		colosedDialog(){
			this.$emit('closeThisDialog')
		},
		//删除附件
		deleteDrawing(child) {
			this.fileList = this.fileList.filter(item => item !== child);
		},
		// 上传
		handleFileUpload(event) {
			if (!this.vLoading){
				this.vLoading = true
				console.log(event,'event')
				const file = event.target.files[0]; // 获取选择的文件
				console.log(file,'file')
				if (file) {
					const Key = 'businessLicense'+'/'+ file.name; // 使用文件名作为上传的Key
					this.uploadFile(file, Key); // 调用上传方法，并传递额外参数 this.uploadFile(file, Key, additionalData); // 调用上传方法，并传递额外参数
					
				}
			}else {
				this.$message({
					message: '正在上传中，请稍后重试！',
					type: 'warning'
				});
			}
		},
		// 上传文件方法
		uploadFile(file, Key) {
			this.obsClient = new ObsClient({
				// Configure your OBS client parameters here
				access_key_id: process.env.VUE_APP_ACCESS_KEY_ID, // Test
				secret_access_key: process.env.VUE_APP_SECRET_ACCESS_KEY, //
				server: process.env.VUE_APP_SERVER_ACCESS_AREA
			});
			console.log(this.obsClient, 'obsClient');
			this.uploading = true; // Set uploading state to true
			
			this.obsClient.putObject({
				Bucket: this.Bucket,
				Key,
				SourceFile: file,
			}, (err, result) => {
				this.uploading = false; // Set uploading state to false
				if (err) {
					console.error('Upload error:', err); // Print error information
				} else {
					this.$message({
						message: '附件上传成功！',
						type: 'success'
					});
					console.log('Upload successful:', result); // Print upload result
					const url = `https://${this.Bucket}.obs.${this.Area}/${Key}`;
					this.businessLicense.push(Key)
					this.fileList.push({
						url: url,
						name: file.name,
					})
					console.log(this.fileList,11111);
					
					this.vLoading = false;
					console.log('File URL:', url); // Print file URL
				}
			});
		},
		// 确定提交
		showConfirmDialogSubmit () {
			if (this.tableForm.naturePlace.length === 0&&this.tableForm.naturePlaceOther.length ===0) {
				this.$message.error('请选择至少一个场所性质');
				return;
			}
			console.log(this.tableForm,'this.tableForm')
			let _this = this
			let params ={...this.tableForm,businessLicenseUrl:''}
			params.buildJsonData = JSON.stringify(this.tableForm.buildJsonData)
			params.buildRenovationJsonData = JSON.stringify(this.tableForm.buildRenovationJsonData)
			params.placeJsonData = JSON.stringify(this.tableForm.placeJsonData)
			// params.venueNatureIds = this.tableForm.naturePlaceOther.join(',')+","+this.tableForm.naturePlace.join(',')
			params.venueNatureIds = [...new Set([...this.tableForm.naturePlaceOther,...this.tableForm.naturePlace])].join(',');
			let arrs = this.tableForm.buildJsonData.map(item => {
				if (item.isUse){
					return item.id
				}
			}).filter(x => x !== undefined && x !== null && x !== ' ');
			let array1= arrs.includes(2)||arrs.includes(3)?11:undefined
			let array2= arrs.includes(5)?12:undefined // 消防水泵房
			// let array3= arrs.includes(4)?5:undefined // 消防控制室
			let array4 = this.tableForm.placeJsonData[1].data[2].isUse?7:undefined //场所是否使用燃气
			let array= this.tableForm.placeJsonData[2].data.map(item => {
				if(item.value) {
					if (item.id){
						return item.id
					}
				}
			}).filter(x => x !== undefined && x !== null && x !== ' ');
			let arr = this.tableForm.placeJsonData[3].data.map(item => {
				if(item.isShow) {
					if (item.id){
						return item.id
					}
				}
			}).filter(x => x !== undefined && x !== null && x !== ' ');
			// console.log(arr,array,'arr') 说明文件 20,21,22,23,24
			params.projectFireFacilitiesIds =  [...new Set([1,2,...arr, ...array,array1,array4,])].filter(x => x !== undefined && x !== null && x !== ' ').join(',');
			params.contactPhone = this.phone
			// params.projectApplicationFileUrl = this.businessLicense.join(',')
			params.projectApplicationFileUrl = 'businessLicense/test'
			console.log(this.querys,'skldajfkdslajdkl')
			params.businessLicenseUrl = 'businessLicense/test'
			params.ddIdProjectType = this.isOr===1?457:415
			this.showConfirmDialogLoading = true
			params.contractId = this.querys?this.querys.id:this.projectId
			params.projectCompany = this.tableForm.projectName
			params.projectName = this.tableForm.projectName.split('\n')[0]
			
			if (this.projectId===0) {
				console.log(params)
				// if (!this.tableForm.contractId ){
				// 	this.$message({
				// 		message: '请选择场所所在地区！',
				// 		type: 'warning'
				// 	});
				//   this.showConfirmDialogLoading = false
				// 	return;
				// }
				addProject(params).then(res => {
					let result = res.data
					if (result.success) {
						this.$message.success('消防安全检查申请报表保存成功!')
						this.$emit('closeThisDialog',result.response)
						console.log(result.response,'resule.response')
						// setTimeout(() => {
						//     _this.$router.back(-1)
						// },500)
					} else {
						this.$message.error(result.msg?result.msg:result.message)
					}
					this.showConfirmDialogLoading = false
				})
			} else {
				params.id = this.projectId
				updateProject(params).then(res => {
					let result = res.data
					if (result.success) {
						this.$message.success('消防安全检查申请报表更新成功!')
						this.$emit('closeThisDialog')
						// setTimeout(() => {
						//     _this.$router.back(-1)
						// },500)
					} else {
						this.$message.error(result.message)
					}
					this.showConfirmDialogLoading = false
				})
			}
			
		},
		// 获取申请表
		getapplication () {
			
			this.tableForm ={
				structureType: undefined,//建筑结构
				naturePlace:[],//场所性质（上）
				naturePlaceOther:[],//场所性质
				applicationPeople : undefined,//申请人
				applicationTimer:undefined,//申请日期
				buildArea:undefined,//场所所在建筑情况：场所建筑面积
				buildHeight : undefined , //场所所在建筑情况：场所高度
				buildJsonData:[
					{
						id:0,
						title:'消防车道',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:1,
						title:'消防车登高操作场地',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:2,
						title:'室外消火栓 ',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:3,
						title:'水泵接合器',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:4,
						title:'消防控制室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						// id:5,
						title:'消防水泵房',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:6,
						title:'消防电梯',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:7,
						title:'柴油发电机房',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:8,
						title:'燃油或燃气锅炉房 ',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:9,
						title:'变压器室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:10,
						title:'配电室',
						isUse:undefined,
						type:'',
						value:undefined,
					},
					{
						id:11,
						title:'其他专用房间',
						isUse:undefined,
						detail:undefined,
						type:'',
						value:undefined,
					},
				],//场所所在建筑情况Json结构体 [{id:0,title:消防车道,type:radio,value:true}]
				buildName : undefined , //场所所在建筑情况：场所名称
				buildOvergroundFloors : undefined , //场所所在建筑情况：场所地上层数
				buildRenovationJsonData : [
					{
						id:0,
						title:'顶棚',
						type:'text',
						value:''
					},
					{
						id:1,
						title:'墙面',
						type:'text',
						value:''
					},
					{
						id:2,
						title:'地面',
						type:'text',
						value:''
					},
					{
						id:3,
						title:'隔断',
						type:'text',
						value:''
					},
					{
						id:4,
						title:'固定家具',
						type:'text',
						value:''
					},
					{
						id:5,
						title:'装饰织物',
						type:'text',
						value:''
					},
					{
						id:6,
						title:'其他',
						type:'text',
						value:''
					},
				] ,//室内装修Json结构体 [{title:顶棚,type:text,value:xxx}]
				buildUndergroundFloors : undefined ,//场所所在建筑情况：场所地下层数
				contactCard : undefined ,//身份证
				contactName : undefined ,//法定代表人/主要负责人
				contactPhone : undefined ,//联系电话
				contractId : undefined ,//合同id
				notes : undefined ,//其他需要说明的情况
				overgroundFloors : undefined ,//地上层数
				placeJsonData : [
					{
						name:'用火',
						data:[
							{
								title:'电气线路设计单位',
								isUse:undefined,
								type:'text',
								value:undefined,
							},
						]
					},
					{
						name:'用电',
						data:[
							{
								title:'电气线路施工单位',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'电器产品是否符合消防安全要求',
								isUse:undefined,
								type:'radio',
								value:'',
							},
							{
								title:'场所是否使用燃气',
								isUse:undefined,
								id:7,
								type:'radio',
								value:''
							},
							{
								title:'燃气类型',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'燃气施工（安装）单位',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'燃气用具是否符合消防安全要求',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'场所是否使用燃油',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'燃油储存位置及储量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'其他用火用电情况',
								isUse:undefined,
								type:'text',
								value:''
							}
						]
					},
					{
						name:'安全疏散',
						data:[
							{
								title:'安全出口数量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'疏散楼梯设置形式',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'疏散楼梯数量',
								isUse:undefined,
								type:'radio',
								value:''
							},
							{
								title:'避难层（间）设置位置',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'避难层（间）数量',
								isUse:undefined,
								type:'text',
								value:''
							},
							{
								title:'消防应急广播',
								type:'text',
								isUse:undefined,
								value:''
							},
							{
								title:'消防应急照明',
								isUse:undefined,
								id:4,
								type:'text',
								value:''
							},
							{
								title:'疏散指示标志',
								isUse:undefined,
								id:4,
								type:'text',
								value:''
							},
						]
					},//
					{
						name:'消防设施',
						data:[
							{
								title:'室内消火栓',
								isUse:undefined,
								id:13,
								type:'text',
								value:''
							},
							{
								title:'自动喷水灭火系统',
								isUse:undefined,
								id:14,
								type:'text',
								value:''
							},
							{
								title:'火灾自动报警系统',
								isUse:undefined,
								id:6,
								type:'text',
								value:''
							},
							{
								title:'气体灭火系统',
								isUse:undefined,
								id:15,
								type:'text',
								value:''
							},
							{
								title:'泡沫灭火系统',
								isUse:undefined,
								id:19,
								type:'text',
								value:''
							},
							{
								title:'机械防烟系统',
								isUse:undefined,
								type:'text',
								id:16,
								value:''
							},
							{
								title:'机械排烟系统',
								isUse:undefined,
								type:'text',
								id:17,
								value:''
							},
							{
								title:'其他消防设施',
								isShow:false,
								isUse:undefined,
								type:'text',
								id:19,
								value:''
							},
							{
								title:'灭火器种类、型号和数量',
								isShow: false,
								isUse:undefined,
								type:'text',
								id:18,
								value:''
							},
						]
					}
				],//场所情况Json结构体 [{name:用火用电,data:[{title:电气线路设计单位,type:text,value:xx}]
				projectAddress : undefined ,//地址
				projectApplicationFileUrl:undefined,//消防安全检查申报表附件
				projectArea : undefined ,//场所建筑面积
				projectFireFacilitiesIds : undefined ,//安全疏散及消防设施 (逗号分割) 1:室内消火栓系统 2:室外消火栓系统 3:火灾自动报警系统 4:自动喷水灭火系统
				// 5:气体灭火系统 6:泡沫灭火系统 7:其他灭火系统 8:疏散指示标志 9:消防应急照明 10:防烟排烟系统 11:消防电梯 12:灭火器 13:其他
				projectId : undefined ,//项目id
				projectName : undefined ,//场所名称
				undergroundFloors : undefined ,//场所地下层数
				venueNatureIds  : undefined ,//	场所性质（逗号分割）（字典命名空间：BizSevice.DrProject.VenueNature）
			}
			this.fileList = []
			this.businessLicense = []
			if (this.needData){
				let params = {
					projectId: this._props.projectId
				}
				getProjectApplicationByProjectId(params).then(res => {
					let result = res.data
					if (result.success) {
						let resp = result.response
						this.tableForm = {
							...resp,
							naturePlaceOther:[],
							naturePlace: [],
						}
						console.log(this.tableForm,'tableforemjaksldjfklsajf')
						this.Id  = resp.id
						this.tableForm.buildJsonData = JSON.parse(resp.buildJsonData)
						this.tableForm.buildRenovationJsonData = JSON.parse(resp.buildRenovationJsonData)
						this.tableForm.placeJsonData = JSON.parse(resp.placeJsonData)
						this.tableForm.projectName = resp.projectCompany
						const nature = new Set(['436', '437', '437', '441', '438', '440', '414']);
						const natureOther = new Set(['443', '444', '445', '446', '447', '448', '451', '449', '442']);
						const nat1 = resp.venueNatureIds.split(',');
						nat1.forEach(item=>{
							if (nature.has(item)) {
								this.tableForm.naturePlace.push(item)
							} else if (natureOther.has(item)) {
								this.tableForm.naturePlaceOther.push(item)
							}
						})
						console.log(this.tableForm.naturePlaceOther,'naturePlaceOther')
						this.tableForm.applicationTimer = this.needData.createTime?this.needData.createTime.split('T')[0]:undefined;
					}
				})
			}
		},
		
		// 获取token
		getToken () {
			return localStorage.getItem('Token')
		},
		// 时间格式化
		formatCreateTime: function (row, column) {
			return !row || row == ''
					? ''
					: util.formatDate.format(new Date(row), 'yyyy-MM-dd hh:mm:ss')
		},
		// //获取用户信息
		// async getUserInfo () {
		// 	let params = {}
		// 	const res = await getAccountInfo(params)
		// 	if (res.data.success) {
		// 		// this.userInfo =
		// 		this.name = res.data.response.name
		// 		this.phone =  res.data.response.phoneNumber
		// 	}
		// },
		// getProvinceList() {
		// 	let params = {
		// 		provinceCode :this.querys.queryValue.first,
		// 		cityCode:this.querys.queryValue.second,
		// 		districtCode :this.querys.queryValue.third,
		// 	}
		// 	getContractByAreaCode(params).then(res => {
		// 		this.provinceList = res.data.response
		// 		console.log(this.provinceList,'省份列表212122')
		// 	})
		// }
		
	},
	computed: {
		headers () {
			return {
				'Authorization': 'Bearer ' + this.getToken() // 直接从本地获取token就行
			}
		},
	},
	watch: {
		'projectId': function (val) {
			this.getapplication()
		},
	},
	created () {
		// this.uploadImagesUrl = api.uploadApplicationPicUrl
		// this.getapplication()
		this.tableForm = {
			...this.tableForms
		}
	}
}
</script>

<style lang="stylus" scoped>
.fileListItem{
	align-items:center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top :10px;
	padding :5px 20px
	border: 1px solid #ccc;
}
.location{
	display: flex;
	align-items: center;
	margin-top :10px
}
.table-td-border{
	padding-left 10px;
}
.titleSty {
	text-align: center;
	font-size: 17px;
	font-weight: bold;
}

.tableTop {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 8px 0;
}

.topInputLeft {
	width: 250px;
	margin: 0 10px;
}

.topInput {
	width: 200px;
	margin: 0 10px;
}

.topInput1 {
	width: 60px;
	margin: 0 10px;
}

.bottomBtn {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding-bottom: 40px;
}

.uploadSty {
	position: fixed;
	top: 20%;
	right: 37%;
	z-index: 10;
	background-color: #fff;
	padding: 30px;
}

.uploadModule {
	background: #606266;
	opacity: .4;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
}

.identifierBox {
	display: flex;
	justify-content: center;
}

.identifier {
	border: 1px solid #409eff;
	border-radius: 50%;
	color: #409eff;
	width: 20px;
	height: 20px;
	line-height: 16px;
	text-align: center;
	font-weight: bolder;
	font-size: 26px;
	cursor: pointer;
}
</style>
